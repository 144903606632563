import cricketIcon from '../assets/images/sportsbook/icons/cricket.svg';
import soccerIcon from '../assets/images/sportsbook/icons/soccer.svg';
import tennisIcon from '../assets/images/sportsbook/icons/tennis.svg';
import kabaddiIcon from '../assets/images/sportsbook/icons/baseball.svg';
import baseball from '../assets/images/sportsbook/icons/baseball.svg';
import basketball from '../assets/images/sportsbook/icons/basketball.svg';
import hockeyIcon from '../assets/images/sportsbook/icons/hockey.svg';
import hourseracingIcon from '../assets/images/sportsbook/icons/hourseracing.svg';
import greyhoundracingIcon from '../assets/images/sportsbook/icons/greyhoundracing.svg';

export const SPORTS_ICONS = {
  Soccer: soccerIcon,
  Football: soccerIcon,
  Tennis: tennisIcon,
  Cricket: cricketIcon,
  Kabaddi: kabaddiIcon,
  horserace: hourseracingIcon,
  greyhound: greyhoundracingIcon,
  Baseball: baseball,
  Basketball: basketball,
};

export const SPORTS_ICONS_WITH_ID = {
  1: soccerIcon,
  2: tennisIcon,
  4: cricketIcon,
  7: kabaddiIcon,
  8: hourseracingIcon,
  9: greyhoundracingIcon,
  7522: baseball,
  7511: basketball,
  'sr:sport:1': soccerIcon,
  'sr:sport:5': tennisIcon,
  'sr:sport:21': cricketIcon,
};
