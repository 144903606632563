import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store';
import { IonIcon, IonCol, IonRow } from '@ionic/react';
import NotificationIcon from '../../assets/images/footer/Notifications.svg';
import './NotificationPage.scss';
import { Notification } from '../../models/Notification';
import { RootState } from '../../models/RootState';
import API from '../../api';
import moment from 'moment';
import RightSideNav1 from '../../views/CommissionRules/RightSideNav/RightSideNav1';
import CompetitionsMenu from '../../components/ExchCompetitionsMenu/ExchCompetitionsMenu';
import { useLocation } from 'react-router-dom';
import API_V2 from '../../api-services/svls-api';
type StoreProps = {
  logout: Function;
  loggedIn: boolean;
};

const NotificationPage: React.FC<StoreProps> = (props) => {
  const { logout, loggedIn } = props;
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const location = useLocation();
  useEffect(() => {
    if (loggedIn) {
      fetchNotifications();
    }
  }, [loggedIn]);
  
  const fetchNotifications = async () => {
    const response = await API_V2.get('/catalog/v2/notifications/', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
      params: {
        type: 'ACTIVE',
      },
    });
    setNotifications(response.data);
  };

  const getNotificationMessages = (notificationsList: Notification[]) => {
    let notfMsg = ' ';
    for (let i = 0; i < notificationsList.length; i++) {
      if (notificationsList[i].active) {
        notfMsg += ' ' + notificationsList[i].message;
        notfMsg += notificationsList[i + 1] ? '  ' + '  '.repeat(8) : '';
      }
    }

    return notfMsg;
  };

  return (
    <>
      <IonRow>
        {location?.pathname !== '/allAnnouncement' ? (
          <IonCol sizeMd="1.7" sizeLg="1.7" className="web-view">
            <div className="compt-menu-section">
              <div className="sticky-col">
                <CompetitionsMenu loggedIn={true} />
              </div>
            </div>
          </IonCol>
        ) : null}
        <IonCol sizeXs="12" sizeSm="12" sizeMd="8.3" sizeLg="8.3">
          <div className="reports-ctn">
            <div className="carw">
              <div className="arrow arrow-right">
                {location?.pathname === '/allAnnouncement'
                  ? 'Announcement'
                  : 'Notification'}
              </div>
            </div>
            {notifications && notifications.length > 0 ? (
              notifications.map((itm, index) => {
                return (
                  <>
                    <div className="card11">
                      {location?.pathname === '/allAnnouncement' ? null : (
                        <div className="icon-ctn">
                          {' '}
                          <IonIcon src={NotificationIcon} className="icon" />
                        </div>
                      )}
                      <div className="text1">
                        {moment(itm.startTime).format('DD-MM-YY ')} -{' '}
                        {itm?.message}
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="card11">
                <div className="icon-ctn">
                  {' '}
                  <IonIcon src={NotificationIcon} className="icon" />
                </div>

                <div className="text1">No Notification</div>
              </div>
            )}
          </div>
        </IonCol>

        {location?.pathname !== '/allAnnouncement' ? (
          <IonCol sizeMd="2" sizeLg="2" className="web-view">
            <div className="compt-menu-section">
              <RightSideNav1 />
            </div>
          </IonCol>
        ) : null}
      </IonRow>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
