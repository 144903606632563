import { IndianCasinoState } from '../../models/IndianCasinoState';
import {
  FATCH_LIVE_CASINO_GAMES,
  SET_SEARCH_GAME,
  HANDLE_SEARCH_GAME,
} from './CasinoActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initailState: IndianCasinoState = {
  searchGame: '',  
  liveCasinoGames: [],
  allLiveCasinoGames: [],
};

const indianCasinoReducer = (
  state = initailState,
  action: Action
): IndianCasinoState => {
  switch (action.type) {
    case FATCH_LIVE_CASINO_GAMES:
      return {
        ...state,
        allLiveCasinoGames: action.payload,
        liveCasinoGames: action.payload,
      };

    case SET_SEARCH_GAME: {
      const searchGame = state.searchGame;
      if (searchGame && !action.payload) {
        return {
          ...state,
          searchGame: action.payload,
          liveCasinoGames: state.allLiveCasinoGames,
        };
      }
      return {
        ...state,
        searchGame: action.payload,
      };
    }

    case HANDLE_SEARCH_GAME: {
      let searchGame = state.searchGame,
        data;
      if (searchGame && state?.allLiveCasinoGames)
        data = state?.allLiveCasinoGames?.filter(
          (item: any) =>
            item?.gameName
              ?.toLowerCase()
              ?.includes(searchGame?.toLocaleLowerCase()) ||
            item?.subProviderName
              ?.toLowerCase()
              ?.includes(searchGame?.toLocaleLowerCase())
        );
      else data = state?.allLiveCasinoGames;
      return {
        ...state,
        liveCasinoGames: data,
      };
    }

    default:
      return state;
  }
};

export default indianCasinoReducer;
