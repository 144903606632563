export const SET_MULTIMARKET_EVENT_DATA = 'SET_MULTIMARKET_EVENT_DATA';
export const UPDATE_MULTIMARKET_SECONDARY_MARKETS =
  'UPDATE_MULTIMARKET_SECONDARY_MARKETS';
export const UPDATE_MULTIMARKET_FANCY_MARKETS =
  'UPDATE_MULTIMARKET_FANCY_MARKETS';
export const UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS =
  'UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS';
export const UPDATE_MULTIMARKET_BOOKMAKER_MARKETS =
  'UPDATE_MULTIMARKET_BOOKMAKER_MARKETS';
export const MULTI_SUSPENDED_MARKETS = 'MULTI_SUSPENDED_MARKETS';
export const MULTI_DISABLED_MARKETS = 'MULTI_DISABLED_MARKETS';
export const TRIGGER_MULTI_FETCH_MARKETS = 'TRIGGER_MULTI_FETCH_MARKETS';
export const TRIGGER_MULTI_FETCH_ORDERS = 'TRIGGER_MULTI_FETCH_ORDERS';
