import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../models/RootState';
import './NewMarket.scss';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import CasinoView from '../../../constants/CasinoView';
import indianpoker from '../../../assets/images/common/indianpoker.png';
import virtualsport from '../../../assets/images/common/virtualsport.png';
import { CASINO_WEB_NAV_PROVIDERS } from '../../../constants/HomeView';
import { useHistory } from 'react-router-dom';
type StoreProps = {};
const NewMarket: React.FC<StoreProps> = (props) => {
  const CasinoCards = CasinoView.CASINO_WEB_NAV_PROVIDERS;
  const history = useHistory();
  const pathName = history.location.pathname;
  return (
    <>
      <div className="casino-providers-ctn">
        <div className="casino-title">
          <span className="casino">Casino</span> Providers
        </div>
        <div className="casino-providers">
          <div className="grid1">
            <div
              className={pathName === '/casino' ? 'card1 active' : 'card1'}
              onClick={() => history.push('/casino')}
            >
              {' '}
              All Providers
            </div>
          </div>
          <div className="grid">
            {CASINO_WEB_NAV_PROVIDERS.map((card, idx) => {
              return (
                <div
                  className={pathName === card.url ? 'card active' : 'card'}
                  key={idx + 'card'}
                  onClick={() => history.push(card.url)}
                >
                  <IonImg src={card.image} className="img-provider" />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="casino-providers-ctn mt">
        <div className="casino-title">
          <span className="casino">Other</span> Sports
        </div>
        <IonRow>
          <IonCol sizeLg="12">
            <img
              src={indianpoker}
              className="sport-img"
              onClick={() => history.push('/casino/royal gaming')}
            />
          </IonCol>

          <IonCol sizeLg="12" onClick={() => history.push('/virtual_games')}>
            <img src={virtualsport} className="sport-img" />
          </IonCol>
        </IonRow>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const selectedEvent = state.exchangeSports.selectedEvent;
  return {
    loggedIn: state.auth.loggedIn,
    openBets: state.exchBetslip.openBets,
    bets: state.exchBetslip.bets,
  };
};
const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMarket);
