import API from '../../api';
import API_V2 from '../../api-services/svls-api';
import {
  FATCH_LIVE_CASINO_GAMES,
  SET_SEARCH_GAME,
  HANDLE_SEARCH_GAME,
} from './CasinoActionTypes';

export const FatchCasinoGamesSuccess = (value: string) => {
  return {
    type: FATCH_LIVE_CASINO_GAMES,
    payload: value,
  };
};
export const FatchLiveCasinoGames = (gametype: string) => {
  return async (dispatch: Function) => {
    try {
      let response = null;
      if (sessionStorage.getItem('jwt_token')) {
        response = await API_V2.get(
          '/catalog/v2/categories/indian-casino/games/',
          {
            params: {
              providerId: '*',
            },
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
      } else {
        response = await API_V2.get(
          '/catalog/v2/categories/indian-casino/games/',
          {
            params: {
              providerId: '*',
            },
          }
        );
      }
      if (response.data) {
        dispatch(FatchCasinoGamesSuccess(response.data));
      } else {
        console.log('fatch casino error');
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSearchGame = (payload) => {
  return {
    type: SET_SEARCH_GAME,
    payload,
  };
};

export const handleSearchGame = () => {
  return {
    type: HANDLE_SEARCH_GAME,
  };
};
