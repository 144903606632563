import { BRAND_DOMAIN } from '../constants/Branding';

const WL_PREFIX_LIST = ['wl1', 'wl2'];
const SUBDOMAIN_LIST = ['dev', 'stage', 'v2'];

export const getSkinUrl = (skinPrefix: string) => {
  const subdomain = getSubdomain(window.location.hostname);
  const domain = getDomain(subdomain, BRAND_DOMAIN);

  if (skinPrefix === '') {
    return subdomain === '' ? domain : concat(subdomain, domain);
  } else {
    return subdomain === ''
      ? concat(skinPrefix, domain)
      : concat(`${subdomain}-${skinPrefix}`, domain);
  }
};

const getSubdomain = (domain: string) => {
  domain = domain.replace('www.', '');
  const domainArr = domain.split('.');

  if (domainArr.length > 2) {
    const subdomain = domainArr[0];
    if (subdomain.includes('-')) {
      return subdomain.split('-')[0];
    } else if (
      WL_PREFIX_LIST.includes(subdomain) ||
      SUBDOMAIN_LIST.includes(subdomain)
    ) {
      return subdomain;
    }
  }

  return '';
};

const getDomain = (subdomain: string, domain: string) => {
  const domainArr = domain.split('.');
  return domainArr.length > 2 && subdomain === domainArr[0]
    ? domainArr.slice(1).join('.')
    : domain;
};

const concat = (prefix: string, domain: string) => {
  return `${prefix}.${domain}`;
};
