import React, { useState, useEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './MobileSideNav.scss';
import CompetitionsMenu from '../../../components/ExchCompetitionsMenu/ExchCompetitionsMenu';
import { useLocation } from 'react-router-dom';
import { ShowlogoAlignment } from '../../../constants/WhitelabelConfig';
import { BRAND_NAME } from '../../../constants/Branding';

const MobileSideNav = (props) => {
  const location = useLocation();
  const [state, setNewState] = useState<{
    isPaneOpen: boolean;
    isPaneOpenLeft: boolean;
  }>({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  // useEffect(() => {
  //   setNewState({ isPaneOpenLeft: false, isPaneOpen: false });
  // }, [location]);

  return (
    <>
      <div className="sidebar-ctn mob-view">
        <div
          onClick={() =>
            setNewState({ isPaneOpenLeft: true, isPaneOpen: true })
          }
        >
          <MenuIcon className={ShowlogoAlignment[BRAND_NAME.toLocaleLowerCase()]?"header-menu-icon small_size_logo_align":"header-menu-icon"} />
        </div>
        <SlidingPane
          className="some-custom-class"
          closeIcon={<div></div>}
          isOpen={state.isPaneOpenLeft}
          title=""
          from="left"
          width="230px"
          onRequestClose={() =>
            setNewState({ isPaneOpenLeft: false, isPaneOpen: false })
          }
        >
          <CompetitionsMenu
            handleMobSideNav={() => {
              setNewState({ isPaneOpenLeft: false, isPaneOpen: false });
            }}
          />
        </SlidingPane>
      </div>
    </>
  );
};

export default MobileSideNav;
