import greyhoundracingIcon from '../assets/images/sportsbook/icons/greyhoundracing.svg';
import hourseracingIcon from '../assets/images/sportsbook/icons/hourseracing.svg';
export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
  { id: '7', name: 'horserace', slug: 'horseracing' },
  // { id: '4339', name: 'greyhound', slug: 'greyhoundracing' },
  // { id: '7511', name: 'Baseball', slug: 'baseball' },
  // { id: '7522', name: 'Basketball', slug: 'basketball' },
];

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
  baseball: '7511',
  basketball: '7522',
  greyhoundracing: '4339',
  horseracing: '7',
  'sr:sport:1': '1',
  'sr:sport:5': '2',
  'sr:sport:21': '4',
};
export const COMING_SOON_EVENT_TYPES = [
  // { id: '', name: 'volleyball', img: volleyball, slug: 'up/volleyball' },
  // {
  //   id: '',
  //   name: 'kabaddi',
  //   slug: 'up/kabaddi',
  //   img: kabaddiIcon,
  //   gameName: 'Kabaddi',
  //   gameCode: 'ids_kabaddiraiders',
  //   gameId: '200300',
  //   platformId: 'desktop',
  //   provider: 'DC',
  //   subProviderName: 'Indi Slots',
  // },

  {
    gameId: '200971',
    provider: 'DC',
    name: 'HorseRacing',
    slug: 'up/horseracing',
    gameName: 'Horserace',
    subProviderName: 'Kiron',
    gameCode: '',
    img: hourseracingIcon,
  },
  {
    gameId: '200972',
    gameName: 'Hockey',
    name: 'greyhound',
    slug: 'up/greyhound',
    provider: 'DC',
    subProviderName: 'Kiron',
    gameCode: '',
    img: greyhoundracingIcon,
  },
];

const EXCHANGE_EVENT_TYPES_GAMES = [
  {
    gameId: 7177,
    gameName: 'Table tennis',
    gameCode: 'krn_tabletennis',
    provider: 'Kiron',
    name: 'Table Tennis',
  },

  {
    gameId: 7187,
    gameName: 'Golf',
    gameCode: 'krn_golf',
    provider: 'Kiron',
    name: 'Golf',
  },

  {
    gameId: 631,
    gameName: 'Kabaddi',
    gameCode: 'ids_kabaddiraiders',
    provider: 'Kabaddi Raiders',
    name: 'Kabaddi',
  },
  {
    gameId: 7175,
    gameName: 'Motor racing (Max Car)',
    gameCode: 'krn_motorracingmaxcar',
    provider: 'Motor racing (Max Car)',
    name: 'Motor Race',
  },
  {
    gameId: 13844,
    gameName: 'Ice Hockey League Round',
    gameCode: 'krn_icehockeyleagueround',
    provider: 'Kiron',
    name: 'Ice Hockey',
  },

  {
    gameId: 6984,
    gameName: 'Golden Race',
    gameCode: 'gdr_goldenrace',
    provider: 'Golden Race',
    name: 'Greyhound Racing',
  },
  {
    gameId: 7173,
    gameName: 'Dogs (Platinum Hounds)',
    gameCode: 'krn_dogsplatinumhounds',
    provider: 'Kiron',
    name: 'Horse Racing',
  },
];
