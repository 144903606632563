import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation, useParams, useHistory } from 'react-router-dom';
import { CompetitionDTO } from '../../models/common/CompetitionDTO';
import { EventType } from '../../models/EventType';
import { RootState } from '../../models/RootState';
import { SelectedObj } from '../../models/ExchangeSportsState';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from '@material-ui/core';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import { isMobile } from 'react-device-detect';
import ClearIcon from '@material-ui/icons/Clear';
import InplayImg from '../../assets/images/sportsbook/icons/inplay.svg';
import CasinoImg from '../../assets/images/sportsbook/icons/casino.svg';
import { SPORTS_ICONS } from '../../constants/SportsNavView';
import ExpandLessSharpIcon from '@material-ui/icons/ExpandLessSharp';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import { EXCH_SPORTS_MAP } from '../../constants/ExchangeEventTypes';
import {
  fetchCompetitions,
  getCompetitionsByEventType,
  setCompetition,
  fetchEventsByCompetition,
} from '../../store';
import './ExchCompetitionsMenu.scss';
import { IonIcon } from '@ionic/react';

type options = [{ name: string; value: string }];

type StoreProps = {
  eventTypes: EventType[];
  competitions: CompetitionDTO[];
  selectedEventType: SelectedObj;
  selectedCompetition: SelectedObj;
  loggedIn?: boolean;
  fetchCompetitions: (eventTypeId: string) => void;
  setCompetition: (competition: SelectedObj) => void;
  fetchEventsByCompetition: (
    eventTypeId: string,
    competitionId: string
  ) => void;
  handleMobSideNav?: any;
};

const CompetitionsMenu: React.FC<StoreProps> = (props) => {
  const {
    eventTypes,
    competitions,
    selectedEventType,
    selectedCompetition,
    loggedIn,
    fetchCompetitions,
    setCompetition,
    fetchEventsByCompetition,
    handleMobSideNav,
  } = props;
  const [tabValue, setTabValue] = useState<number>(0);
  const [subTabValue, setSubTabValue] = useState<number>(0);
  const [openCompetitionList, setOpenCompetitionList] =
    React.useState<string>('');
  const [openEventList, setOpenEventList] = React.useState<boolean>(false);
  const [id, setId] = React.useState<String>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [gameId, setGameId] = useState<String>('');
  const [gameName, setGameName] = useState<String>('');
  const [gameCode, setGameCode] = useState<String>('');
  const [provider, setProvider] = useState<String>('');
  const [subProvider, setSubProvider] = useState<String>('');
  const history = useHistory();
  function handleOpenCompetitionList(data) {
    setOpenCompetitionList((prevState) => (prevState === data ? '' : data));
  }
  function handleOpenEventList(id: String) {
    setOpenEventList(!openEventList);
    setId(id);
  }

  const pathParams = useParams();
  const competition = pathParams['competition'];
  const location = useLocation();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setSubTabValue(newValue);
  };

  const handleCompetitionChange = (c: CompetitionDTO) => {
    setCompetition({ id: c.id, name: c.name, slug: c.slug });
    fetchEventsByCompetition(selectedEventType.id, c.id);
  };

  useEffect(() => {
    if (location.pathname.startsWith('/exchange_sports/inplay')) {
      if (!competitions) {
        fetchCompetitions(selectedEventType.id);
      } else {
        if (competition && selectedCompetition.slug! === competition) {
          for (let i = 0; i < competitions.length; i += 1) {
            if (competitions[i].slug === competition && i !== tabValue) {
              setTabValue(i);
              handleCompetitionChange(competitions[i]);
              break;
            }
          }
        }
      }
    }
  }, [competitions, selectedCompetition]);

  useEffect(() => {
    if (!competition) {
      setCompetition({ id: '', name: '', slug: '' });
    }
  }, []);
  const closeDialog = async () => {
    await setOpenDialog(false);
    getGameUrl(
      gameId.toString(),
      gameName.toString(),
      gameCode.toString(),
      provider.toString(),
      subProvider.toString()
    );
  };
  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    history.push(
      `/gap_casino/game/${gameName.toLowerCase().replace(/\s+/g, '-')}-${btoa(
        gameId.toString()
      )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
    );
  };
  const closeSideNav = () => {
    if (isMobile) handleMobSideNav();
  };
  const getCompetitionName = (name, sportId) => {
    if (sportId === '7' || sportId === '4339') {
      let fisrtName = name?.split('_')[0];
      let lastName = name?.split('_')[1];
      return lastName + '  (' + fisrtName + ') ';
    } else {
      return name;
    }
  };

  return (
    <>
      <div className="competitions-menu">
        <List
          component="nav"
          disablePadding
          className="competitions-menu-card-content"
        >
          <NavLink
            to="/exchange_sports/inplay"
            className="eventTypes-menu-nav-link"
          >
            <ListItem
              button
              className={
                location && location.pathname === '/exchange_sports/inplay'
                  ? 'active'
                  : ''
              }
              onClick={() => closeSideNav()}
            >
              <ListItemIcon className="icon-ctn">
                <img
                  src={InplayImg}
                  className="icon"
                  alt="inPlay"
                  height={20}
                  width={20}
                />
              </ListItemIcon>

              <ListItemText primary="In play" />
            </ListItem>
          </NavLink>

          {eventTypes.map((sport, index) =>
            EXCH_SPORTS_MAP[sport.slug] !== 0 ? (
              <React.Fragment key={sport.slug + sport.name + index}>
                <NavLink
                  to={`/exchange_sports/${sport.slug}`}
                  className={'eventTypes-menu-nav-link'}
                >
                  <ListItem
                    button
                    onClick={() => {
                      if (sport.id === '6') return;
                      fetchCompetitions(sport.id);
                      setCompetition({ id: '', name: '', slug: '' });
                      handleOpenCompetitionList(sport?.slug);
                    }}
                  >
                    <ListItemIcon className="icon-ctn">
                      <IonIcon
                        className={`sport-icon${index}`}
                        src={
                          SPORTS_ICONS[sport.name]
                            ? SPORTS_ICONS[sport.name]
                            : SPORTS_ICONS['Other']
                        }
                      />
                    </ListItemIcon>

                    <ListItemText primary={sport.name} />
                    {location.pathname === `/exchange_sports/${sport?.slug}` &&
                    openCompetitionList ? (
                      <ExpandLessSharpIcon className="expand-icon" />
                    ) : (
                      <ExpandMoreSharpIcon className="expand-icon" />
                    )}
                  </ListItem>
                </NavLink>

                <Collapse
                  in={openCompetitionList === sport?.slug}
                  timeout="auto"
                  unmountOnExit
                  className="Collapse-list"
                >
                  {sport.slug === selectedEventType.slug ? (
                    competitions ? (
                      competitions.length > 0 ? (
                        <List component="div" disablePadding>
                          {competitions.map((c, idx) => (
                            <>
                              <NavLink
                                className="eventTypes-menu-nav-link"
                                key={c.slug + c.name + idx + '-link'}
                                onClick={() => {
                                  handleCompetitionChange(c);
                                }}
                                to={`/exchange_sports/${selectedEventType.slug}/${c.slug}`}
                              >
                                <ListItem
                                  button
                                  onClick={() => closeSideNav()}
                                  className={
                                    location &&
                                    location.pathname ===
                                      `/exchange_sports/${selectedEventType.slug}/${c.slug}`
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  <ListItemText
                                    inset
                                    primary={getCompetitionName(
                                      c.name,
                                      c.sportId
                                    )}
                                  />
                                </ListItem>
                              </NavLink>
                              <Divider />
                            </>
                          ))}
                        </List>
                      ) : (
                        <List
                          component="div"
                          disablePadding
                          className="no-competitions"
                        >
                          <ListItem>
                            <ListItemText inset primary="No Competitons" />
                          </ListItem>
                          <Divider></Divider>
                        </List>
                      )
                    ) : null
                  ) : null}
                </Collapse>
              </React.Fragment>
            ) : null
          )}
          <NavLink
            to="/casino/Royal Gaming"
            className="eventTypes-menu-nav-link"
          >
            <ListItem
              button
              className={
                location && location.pathname === '/casino' ? 'active' : ''
              }
              onClick={() => closeSideNav()}
            >
              <ListItemIcon className="icon-ctn">
                <IonIcon src={CasinoImg} className="sport-icon2" />
              </ListItemIcon>

              <ListItemText primary="Casino" />
            </ListItem>
          </NavLink>
        </List>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => closeDialog()}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="go-corner" onClick={() => closeDialog()}>
            <div className="go-arrow">
              <ClearIcon />
            </div>
          </div>
          <div className="dialog-title">
            <StarBorderRoundedIcon />
            Live Casino Info
            <StarBorderRoundedIcon />
          </div>
          <div className="dialog-body">
            Live Casino Point is equal to 100 Normal Points.
            <div className="dialog-ex">
              {' '}
              Ex: 100 Casino Points = 10000 Points
            </div>
          </div>
          <div className="dialog-continue" onClick={() => closeDialog()}>
            <div className="custom-btn btn">Continue</div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    eventTypes: state.exchangeSports.eventTypes,
    competitions: getCompetitionsByEventType(
      state.exchangeSports.competitions,
      state.exchangeSports.selectedEventType.id
    ),
    selectedEventType: state.exchangeSports.selectedEventType,
    selectedCompetition: state.exchangeSports.selectedCompetition,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchCompetitions: (eventTypeId: string) =>
      dispatch(fetchCompetitions(eventTypeId)),
    setCompetition: (competition: SelectedObj) =>
      dispatch(setCompetition(competition)),
    fetchEventsByCompetition: (eventTypeId: string, competitionId: string) =>
      dispatch(fetchEventsByCompetition(eventTypeId, competitionId, [])),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionsMenu);
