import { IonRow, IonIcon } from '@ionic/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { BRAND_NAME } from '../../constants/Branding';
import './Copyright.scss';

const Copyright: React.FC = () => {
  return (
    <div className="footer-ctn">
      <IonRow class="copyright-ctn">
        <div>
          Copyrights {new Date().getFullYear()}, {BRAND_NAME} | All rights
          reserved, {}
          <NavLink to="/rules" className="navlink web-view">
            Rules and Regulations
          </NavLink>
        </div>
        <div className="mob-view">
          <NavLink to="/rules" className="navlink">
            Rules and Regulations
          </NavLink>
        </div>
      </IonRow>
    </div>
  );
};

export default Copyright;
