import {
  IonSpinner,
  IonItem,
  IonLabel,
  IonButton,
  IonToast,
} from '@ionic/react';
import React, { useState, useEffect, useCallback } from 'react';
import API from '../../api/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import './BetsListPopper.scss';
import Paper from '@material-ui/core/Paper';
import { NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setExchEvent, setEventType, setCompetition } from '../../store';
import { getSportIdByName } from '../../util/stringUtil';
import { UserBet } from '../../models/UserBet';
import { EventType } from '../../models/EventType';
import { RootState } from '../../models/RootState';
import { IPL_CUP, WORLD_CUP } from '../../constants/IPLEvent';

type SelectedObj = {
  id: string;
  name: string;
  slug: string;
};

type StoreProps = {
  eventTypes: EventType[];
  setExchEvent: (event: SelectedObj) => void;
  setEventType: (event: SelectedObj) => void;
  setCompetition: (event: SelectedObj) => void;
};

type BetsListPopperProps = StoreProps & {
  closePopover: () => void;
};

const BetsListPopper: React.FC<BetsListPopperProps> = (props) => {
  const {
    setExchEvent,
    setEventType,
    setCompetition,
    closePopover,
    eventTypes,
  } = props;
  const [bets, setBets] = useState<UserBet[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorText, setErrorText] = useState<string>(null);

  const checkBackDays = 7;
  let history = useHistory();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const response = await API.get('/sap/reports/my-bets', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
      params: {
        games: 'sports',
        filter: 'open',
        page: 1,
        sortDesc: true,
        dateFrom: moment()
          .subtract(checkBackDays, 'd')

          .startOf('day')
          .toISOString(),
        dateTo: moment().endOf('day').toISOString(),
      },
    });

    let allBets = response.data;
    let betListByCount = [];
    for (let bt of allBets) {
      let x = allBets.filter((b) => {
        return bt.eventId === b.eventId;
      });

      let betobj = { ...bt, count: x.length };
      betListByCount.push(betobj);
    }

    let uniqBetsMap: { [key: string]: UserBet } = {};
    for (var b of betListByCount) {
      uniqBetsMap[b.eventId] = b;
    }

    let betEvents: UserBet[] = [];
    Object.keys(uniqBetsMap).forEach(function (key) {
      if (betEvents.length <= 6) betEvents.push(uniqBetsMap[key]);
    });

    setBets(betEvents);
    setLoading(false);
  }, []);

  const getSportName = (id) => {
    if (id !== '-') {
      return eventTypes.filter(
        (e) => e.id === getSportIdByName(id?.toLowerCase())
      )[0]?.name;
    }
    return 'Binary';
  };
  const getSportSlug = (id) => {
    if (id !== '-') {
      return eventTypes.filter(
        (e) => e.id === getSportIdByName(id?.toLowerCase())
      )[0].slug;
    }
    return 'binary';
  };
  const getSlug = (value) => {
    return value
      .toLowerCase()
      .replace('vs.', 'vs')
      .replace(/[^a-z0-9]/g, ' ')
      .replace(/ +/g, ' ')
      .trim()
      .split(' ')
      .join('-');
  };
  const redirectToAllMarkets = (bet) => {
    let redirectURL = '/exchange_sports/';
    if (bet.eventName.toLowerCase() === 'binary') {
      redirectURL += 'binary';
    } else if (bet.eventName.toLowerCase() === 'indian premier league') {
      redirectURL += `/exchange_sports/cricket/indian-premier-league/winner/${btoa(
        IPL_CUP.routeHash
      )}`;
    } else {
      const sSlug = getSportSlug(bet.sportId);
      const tSlug = getSlug(bet.seriesName);
      const eSlug = getSlug(bet.eventName);

      setEventType({
        id: bet.sportId,
        name: getSportName(bet.sportId),
        slug: getSportSlug(bet.sportId),
      });
      setCompetition({
        id: bet.seriesId,
        name: bet.seriesName,
        slug: bet.seriesName,
      });
      setExchEvent({
        id: bet.eventId,
        name: bet.eventName,
        slug: bet.eventName,
      });
      redirectURL += `${sSlug}/${tSlug}/${eSlug}/${btoa(
        `${getSportIdByName(bet.sportId.toLowerCase())}:${bet.seriesId}:${
          bet.eventId
        }`
      )}`;
    }
    closePopover();
    history.push(redirectURL);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="bets-list-popper-ctn">
      {/* //   <span className="close-icon hand-pt" onClick={() => closePopover()}>
    //     <CloseIcon />
    //   </span>
    //   <div className="blp-title">Open Bets</div> */}
      <div className="title">Open Bets</div>

      <div>
        {loading ? (
          <IonItem lines="none">
            <IonLabel>Loading...</IonLabel>
            <IonSpinner name="lines" />
          </IonItem>
        ) : bets.length > 0 ? (
          <>
            <IonToast
              isOpen={errorText !== null}
              onDidDismiss={() => setErrorText(null)}
              message={errorText}
              color="danger"
              position="top"
              duration={1000}
            />
            <div className="bets-ctn">
              <TableContainer component={Paper}>
                <Table className="bets-tbl" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="left">Type</TableCell> */}
                      <TableCell align="left">Teams</TableCell>
                      <TableCell align="right">Bets</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bets.map((bet, index) => (
                      <TableRow
                        key={index}
                        // onClick={() => redirectToAllMarkets(bet)}
                      >
                        {/* <TableCell
                          align="left"
                          className="col-content-sub-headers"
                        >
                          {getSportName(bet.sportId)}
                        </TableCell> */}
                        <TableCell align="left">
                          {bet.eventName && bet.eventName.includes(' v ') ? (
                            <>
                              <div className="col-content-headers">
                                {bet.eventName.split(' v ')[0]}
                              </div>
                              <div className="col-content-headers">
                                {bet.eventName.split(' v ')[1]}
                              </div>
                            </>
                          ) : (
                            <div className="col-content-headers">
                              {bet.eventName}
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          className="col-content-headers"
                          align="right"
                        >
                          {bet.count}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          <div className="no-data">
            No Open bets in last {checkBackDays} days to display.
          </div>
        )}
          <NavLink to="/my_bets">
            <IonButton
              className="btn"
              onClick={() => closePopover()}
            >
              View All Bets
            </IonButton>
          </NavLink>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
    setEventType: (eType: SelectedObj) => dispatch(setEventType(eType)),
    setCompetition: (competition: SelectedObj) =>
      dispatch(setCompetition(competition)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    eventTypes: state.exchangeSports.eventTypes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetsListPopper);
