// Web Banners
import andarbaharBanner from '../assets/images/banners/andarbahar-banner.svg';
// Mobile banners
import mobileAndarbaharBanner from '../assets/images/banners/mobile-andarbahar.svg';
import CasinoHome from '../assets/images/banners/casino_home.png';
import Img1 from '../assets/images/casino/cards/1.svg';
import Img2 from '../assets/images/casino/cards/5.svg';
import Img3 from '../assets/images/casino/cards/2.svg';
import Img4 from '../assets/images/casino/cards/11.svg';
import Img5 from '../assets/images/casino/cards/12.svg';
import Img6 from '../assets/images/casino/cards/7.svg';
import Img7 from '../assets/images/casino/cards/13.svg';
import Img8 from '../assets/images/casino/cards/14.svg';
import Img9 from '../assets/images/casino/cards/15.svg';
import Img10 from '../assets/images/casino/cards/16.svg';
import Img11 from '../assets/images/casino/cards/17.svg';

import Img12 from '../assets/images/casino/cards/18.svg';
import Img13 from '../assets/images/casino/cards/19.svg';
import Img14 from '../assets/images/casino/cards/20.svg';
import Img15 from '../assets/images/casino/cards/21.svg';
import Img16 from '../assets/images/casino/cards/22.svg';
import Img17 from '../assets/images/casino/cards/23.svg';
import Img18 from '../assets/images/casino/cards/24.svg';
// import Img17 from '../assets/images/common/17.png';
// import Img18 from '../assets/images/common/18.png';
// import Img19 from '../assets/images/common/19.png';
// import Img20 from '../assets/images/common/20.png';

const CASINO_WEB_BANNERS = [
  {
    image: CasinoHome,
    text: 'Roulette',
  },
];

const IND_CASINO_WEB_BANNERS = [
  {
    image: andarbaharBanner,
    text: 'Andhar Bahar',
  },
];

const CASINO_MOBILE_BANNERS = [
  {
    image: CasinoHome,
    text: 'Roulette',
  },
];

const IND_CASINO_MOBILE_BANNERS = [
  {
    image: mobileAndarbaharBanner,
    text: 'andhar bahar',
  },
];
const CASINO_WEB_NAV_PROVIDERS = [
  {
    image: Img1,
    text: 'Royal Casino',
    url: '/casino/royal gaming',
  },
  {
    image: Img2,
    text: 'Evolution',
    url: '/casino/evolution gaming',
  },
  {
    image: Img3,
    text: 'Betsoft',
    url: '/casino/betsoft',
  },
  {
    image: Img4,
    text: 'AEsexy',
    url: '/casino/sexybcrt',
  },
  // {
  //   image: Img5,
  //   text: 'blue print',
  //   url: '/casino',
  // },
  // {
  //   image: Img6,
  //   text: 'Pragmatic Play 2',
  //   url: '/casino/super spade games',
  // },
  {
    image: Img8,
    text: 'Alchemy',
    url: '/casino/alchemy gaming',
  },
  {
    image: Img9,
    text: 'blue guru',
    url: '/casino/blue guru',
  },
  {
    image: Img10,
    text: 'game burger',
    url: '/casino/gameburger',
  },
  {
    image: Img11,
    text: 'jili',
    url: '/casino/jili gaming',
  },
  {
    image: Img12,
    text: 'hacksaw',
    url: '/casino/hacksaw gaming',
  },
  {
    image: Img13,
    text: 'indi slots',
    url: '/casino/indi slots',
  },
  {
    image: Img14,
    text: 'golden hero',
    url: '/casino/golden hero',
  },
  {
    image: Img15,
    text: 'evoplay entertainment',
    url: '/casino/evoplay entertainment',
  },
  // {
  //   image: Img16,
  //   text: 'dreams casino',
  //   url: '/casino/dreams casino',
  // },

  {
    image: Img18,
    text: 'removo-mob',
    url: '/casino/Buck Stakes Entertainment',
  },
];

export default {
  CASINO_WEB_BANNERS,
  CASINO_MOBILE_BANNERS,
  IND_CASINO_WEB_BANNERS,
  IND_CASINO_MOBILE_BANNERS,
  CASINO_WEB_NAV_PROVIDERS,
};
