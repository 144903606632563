export const BUTTON_VARIABLES = [
  { label: '100', stake: 100 },
  { label: '500', stake: 500 },
  { label: '1,000', stake: 1000 },
  { label: '5,000', stake: 5000 },
  { label: '10,000', stake: 10000 },
  { label: '50,000', stake: 50000 },
  // { label: '1,00,000', stake: 100000 },
  // { label: '5,00,000', stake: 500000 },
  // { label: '10,00,000', stake: 1000000 },
];
