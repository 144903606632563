import React from 'react';
import './LoginPage.scss';
import LoginForm from '../../components/LoginForm/LoginForm';
import { IonApp, IonContent } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Copyright from '../../components/Copyright/Copyright';

const Login: React.FC = () => {
  let history = useHistory();
  return (
    <>
      <IonApp>
        <Header />
        <IonContent className="background">
          <div className="login-card">
            <LoginForm />
          </div>
          <div className="copy-right">
            <Copyright></Copyright>
          </div>
        </IonContent>
      </IonApp>
    </>
  );
};

export default Login;
