import axios from 'axios';
import store from '../../store/store';
import { logout } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../models/RootState';
import { getBaseUrl } from '../environment-url/environment-url';

const API_V2 = axios.create({
  baseURL: getBaseUrl(
    process.env.REACT_APP_NODE_ENV,
    'REACT_APP_REST_SVLS_API_URL'
  ),
  withCredentials: false,
  timeout: 1000 * 20, // 20 seconds
});

//Add a response interceptor
API_V2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('***********Unauthorized Error**********');
      const token = sessionStorage.getItem('jwt_token');
      if (token) {
        (store.dispatch as ThunkDispatch<RootState, void, AnyAction>)(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default API_V2;
