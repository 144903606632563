import React, { useState } from 'react';
import './RightSideNav.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../models/RootState';
import NewMarket from '../NewMarket/NewMarket';
type SidebarProps = {
  loggedIn?: boolean;
};

const RightSideNav1: React.FC<SidebarProps> = (props) => {
  return (
    <>
      <NewMarket />
      {/* <AddNewMarket isAccordion={true}/> */}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps, null)(RightSideNav1);
