import logoIcon from '../assets/images/brand/title.png';
import whitelogoIcon from '../assets/images/brand/whitelogo.png';
import orangelogoIcon from '../assets/images/brand/logoorange.png';
import { ShowThemelogo } from './WhitelabelConfig';
import { BRAND_NAME } from './Branding';

export const ShowThemelogoGreenTheme: { [key: string]: boolean } = {
  betfair: true,
  shettyonline: true,
  a2zbets: true,
};

export const LOGO_ICONS = {
  light: ShowThemelogo[BRAND_NAME.toLocaleLowerCase()]
    ? whitelogoIcon
    : logoIcon,
  dark: logoIcon,
  purple: whitelogoIcon,
  green: whitelogoIcon,
  blue: whitelogoIcon,
  orange: ShowThemelogo[BRAND_NAME.toLocaleLowerCase()]
    ? whitelogoIcon
    : orangelogoIcon,
  pink: whitelogoIcon,
  pink1: whitelogoIcon,
  green1: ShowThemelogo[BRAND_NAME.toLocaleLowerCase()]
    ? whitelogoIcon
    : logoIcon,
  brown: whitelogoIcon,
};

export const TOSS_ODD_VALUE = 100;
