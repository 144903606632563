import moment from 'moment';
import {
  getAccountPathFromToken,
  getSportsBookFromToken,
} from '../auth/authActions';
import {
  TOGGLE_DARK_MODE,
  SET_LIVESTREAM_URL,
  SET_PLAY_STREAM,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
  TRIGGER_FETCH_BALANCE,
  TRIGGER_FETCH_NOTIFICATIONS,
} from './commonActionTypes';

export const toggleDarkMode = (value: string) => {
  return {
    type: TOGGLE_DARK_MODE,
    payload: value,
  };
};

export const setPlayStream = (value: boolean) => {
  return {
    type: SET_PLAY_STREAM,
    payload: value,
  };
};

export const setLivestreamUrl = (url: string) => {
  return {
    type: SET_LIVESTREAM_URL,
    payload: url,
  };
};

export const setAllowedConfig = (allowedConfig: number) => {
  return {
    type: SET_ALLOWED_CONFIG,
    payload: allowedConfig,
  };
};

export const enableCommission = (commission: boolean) => {
  return {
    type: ENABLE_COMMISSION,
    payload: commission,
  };
};

export const triggerFetchBalance = (eventTime: number) => {
  return {
    type: TRIGGER_FETCH_BALANCE,
    payload: eventTime,
  };
};

export const triggerFetchNotifications = (data) => {
  var adminAccountPath = data.adminAccountPath.concat('/');
  if (
    getAccountPathFromToken().includes(adminAccountPath) &&
    (data.sportsBooks.includes('all') ||
      data.sportsBooks.includes(getSportsBookFromToken()))
  ) {
    return {
      type: TRIGGER_FETCH_NOTIFICATIONS,
      payload: moment.now(),
    };
  }
};

export const isAccountPath = (limitKey: string) => {
  return !limitKey.includes('/CT/SPORTS');
};
